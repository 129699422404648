import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
	{
	  path: '/news',
	  name: 'News',
	  component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
	},
	{
	  path: '/education',
	  name: 'Education',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Education.vue')
	},
	{
	  path: '/conference',
	  name: 'Conference',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Conference.vue')
	},
	{
	  path: '/jobs',
	  name: 'Jobs',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Jobs.vue')
	},
	{
	  path: '/members',
	  name: 'Members',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Members.vue')
	},
	{
	  path: '/search/:r',
	  name: 'Search',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
	}
]

const router = new VueRouter({
  routes
})

export default router
