<template>
	<nav class="navbar navbar-expand-lg navbar-light fixed-top">
		<div class="container pt-15 pb-15">
			<router-link class="navbar-brand" to="/">
				<img src="../assets/imgs/LOGO.png" alt="">
			</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			  <span class="navbar-toggler-icon"></span>
			</button>
				
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
			  <ul class="navbar-nav ml-auto">
			    <li class="nav-item active">
						<router-link to="/">Home</router-link>
			    </li>
					<li class="nav-item">
						<router-link to="/about">About Us</router-link>
					</li>
			    <li class="nav-item">
						<router-link to="/news">News</router-link>
			    </li>
					<li class="nav-item">
						<router-link to="/education">Education</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/conference">Conference</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/jobs">Jobs</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/members">Members</router-link>
					</li>
			  </ul>
			</div>
		</div>
	</nav>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		created() {
		}
	}
</script>

<style scoped lang="scss">
	.navbar {
		background-color: #FFFFFF;
	}
	.navbar-brand img {
		height: 100px;
	}
	@media only screen and (max-width: 767px) {
	  .navbar-brand img {
	  	height: 50px;
	  }
		.navbar-nav {
			padding-top: 30px;
		}
	}
	.nav-item {
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		align-items: center;
	}
	.nav-item {
		a.router-link-exact-active {
			color: #F1a822 !important;
		}
	}
</style>
