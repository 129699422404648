<template>
  <div>
		<Header />
		<Slide />
		<div class="container">
			<div class="input-group input-group-lg mb-5 mt-5">
			  <input
					v-model="searchVal"
					type="text"
					class="form-control"
					placeholder="Search name, membership ID...">
			  <div class="input-group-append">
			    <span class="input-group-text" @click="clickFun" style="background-color: #333; color: #FFF; cursor: pointer;">Check</span>
			  </div>
			</div>
		</div>
		<Footer />
  </div>
</template>

<script>
	import Header from '@/components/Header'
	import Slide from '@/components/Slide'
	import Edu from '@/components/Edu'
	import Footer from '@/components/Footer'
	export default {
		name: 'Index',
		components: {
			Header,
			Slide,
			Edu,
			Footer
		},
		data() {
			return {
				searchVal: ''
			}
		},
		created() {
		},
		methods: {
			clickFun() {
				if(this.searchVal == "") {
					this.searchVal = " "
				}
				this.$router.push(`/search/${this.searchVal}`)
			}
		}
	}
</script>
